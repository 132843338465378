module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#4d758f","theme_color":"#ff9966","display":"minimal-ui","icon":"src/images/sf-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0a6b516a372afa1b2126471b072a374b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/bryantgutierrez/Dev/GildedLily/san-fran-cafe/locales","languages":["en","es"],"defaultLanguage":"es","redirect":false,"siteUrl":"https://sanfran.cafe","i18nextOptions":{"debug":true,"lowerCaseLng":true,"saveMissing":false,"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
